import {useState} from "react";
import {
  CopyRight,
  FooterBody,
  FooterContainer,
  IconWrapper,
  MenuItem,
  MenuLink,
  MenuNavigationContainer,
  SectionTitle,
  SocialMedia,
} from "./footer-elements";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaGooglePlusSquare,
  FaTwitterSquare,
  FaSkype,
} from "react-icons/fa";
import useVisibleComponent from "../../../hooks/useVisibleComponent";

function Footer() {
  const opacity = useVisibleComponent("footer");

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);

  return (
    <FooterContainer opacity={opacity} id="footer">
      <SectionTitle>Contact Us</SectionTitle>
      <FooterBody>
      {isEmailSent ?<>
       <SectionTitle>
        <div>We received your message!</div>
        <div></div>
        </SectionTitle>
       </> : 
        <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
          <InputText placeholder="Name" value={name} onChange={setName}/>
          <InputText placeholder="Email address" value={email} onChange={setEmail}/>
          <button disabled={name.length < 2 && email.length < 4} onClick={() => setIsEmailSent(true)}
           style={{padding:16, backgroundColor: 'lightgreen'}}>Send</button>
        </div>}
        {/* <SocialMedia>
          <IconWrapper>
            <FaFacebookSquare />
          </IconWrapper>
          <IconWrapper>
            <FaInstagramSquare />
          </IconWrapper>
          <IconWrapper>
            <FaGooglePlusSquare />
          </IconWrapper>
          <IconWrapper>
            <FaTwitterSquare />
          </IconWrapper>
          <IconWrapper>
            <FaSkype />
          </IconWrapper>
        </SocialMedia> */}
        <MenuNavigationContainer style={{marginTop: 60}}>
          <MenuItem>
            <MenuLink
              to="home"
              smooth={true}
              duration={300}
              spy={true}
              exact={true}
              offset={-80}
            >
              Home
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink
              to="about"
              smooth={true}
              duration={300}
              spy={true}
              exact={true}
              offset={-80}
            >
              Services
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink
              to="members"
              smooth={true}
              duration={300}
              spy={true}
              exact={true}
              offset={-80}
            >
              customers
            </MenuLink>
          </MenuItem>
        </MenuNavigationContainer>
      </FooterBody>
      <CopyRight style={{paddingBottom:16}}>@2022 Omnibit LTD.</CopyRight>
    </FooterContainer>
  );
}

function InputText({placeholder, value, onChange}){
  return <input type="text" style={{padding: 12}} placeholder={placeholder} value={value} onChange={e => onChange(e.currentTarget.value)}/>
}

export default Footer;
