import { useEffect, useState } from "react";
import useVisibleComponent from "../../../hooks/useVisibleComponent";
import {
  AboutUsSectionContainer,
  Card,
  CardContainer,
  NumberMessage,
  SectionTitle,
  Description,
} from "./about-us-section-elements";

function AboutUsSection() {
  const opacity = useVisibleComponent("about");

  return (
    <AboutUsSectionContainer opacity={opacity} id="about">
      <SectionTitle>Omnibit Services</SectionTitle>
      <CardContainer>
        <Card id="card-1">
          <NumberMessage>
            <i className="fa fa-tools" style={{fontSize: 120}}/>
          </NumberMessage>
          <Description>Professional Services</Description>
        </Card>
        <Card id="card-2">
          <NumberMessage>
            <i className="fa fa-code" style={{fontSize: 120}}/>
          </NumberMessage>
          <Description>Software Development</Description>
        </Card>
        <Card id="card-3">
          <NumberMessage>
            <i className="fa fa-user-tie" style={{fontSize: 120}}/>
          </NumberMessage>
          <Description>Consulting Services</Description>
        </Card>
      </CardContainer>
    </AboutUsSectionContainer>
  );
}

export default AboutUsSection;
