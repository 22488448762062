import "./App.css";
import DashBoardPage from "./pages/main/dashboard";

function App() {
  return (
    <div className="App">
      <DashBoardPage />
      {/* <Routes>
        <Route path="/" element={<DashBoardPage />} />
        <Route path="/index.html" element={<DashBoardPage />} />
      </Routes> */}
    </div>
  );
}

export default App;
