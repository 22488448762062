import { useEffect, useState } from "react";
import useVisibleComponent from "../../../hooks/useVisibleComponent";
import {
  GuildMembersSectionContainer,
  SectionTitle,
  SliderContainer,
  SliderButton,
  SliderTool,
  SliderList,
  SliderItem,
  SliderCard,
  SliderCardImage,
  SliderCardTitle,
  SliderCardDescription,
} from "./guild-members-section-element";

function GuildMemberSection() {
  const opacity = useVisibleComponent("members");

  return (
    <GuildMembersSectionContainer opacity={opacity} id="members">
      <SectionTitle>Customers</SectionTitle>
<div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 250px)', gap: 42, marginTop: 60}}>
      <SliderCard>
              <SliderCardImage src="https://storage.googleapis.com/bubble-finder/$qt21GG5ohYV3JURlouh3IMfjY883nzxy53IeNOg5eQR4fosc4W3g9G" />
              <SliderCardTitle>Canonic</SliderCardTitle>
              {/* <SliderCardDescription>Developer</SliderCardDescription> */}
            </SliderCard>
            <SliderCard>
              <SliderCardImage src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/z9rwz8mloa5zuslmp9nx" />
              <SliderCardTitle>Metropolinet</SliderCardTitle>
              {/* <SliderCardDescription>Developer</SliderCardDescription> */}
            </SliderCard>

            <SliderCard>
              <SliderCardImage src="https://media.glassdoor.com/sql/3535596/1xhub-squarelogo-1663745466146.png" />
              <SliderCardTitle>1Xhub</SliderCardTitle>
              {/* <SliderCardDescription>Tester</SliderCardDescription> */}
            </SliderCard>
          </div>

      {/* <SliderContainer>
        <SliderTool>
          <SliderButton>&#60;</SliderButton>
          <SliderButton>&#62;</SliderButton>
        </SliderTool>
        <SliderList>
          <SliderItem>
            <SliderCard>
              <SliderCardImage src="https://storage.googleapis.com/bubble-finder/$qt21GG5ohYV3JURlouh3IMfjY883nzxy53IeNOg5eQR4fosc4W3g9G" />
              <SliderCardTitle>Canonic</SliderCardTitle>
              <SliderCardDescription>Developer</SliderCardDescription>
            </SliderCard>
          </SliderItem>
          <SliderItem>
            <SliderCard>
              <SliderCardImage src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/z9rwz8mloa5zuslmp9nx" />
              <SliderCardTitle>Metropolinet</SliderCardTitle>
              <SliderCardDescription>Manager</SliderCardDescription>
            </SliderCard>
          </SliderItem>
          <SliderItem>
            <SliderCard>
              <SliderCardImage src="https://media.glassdoor.com/sql/3535596/1xhub-squarelogo-1663745466146.png" />
              <SliderCardTitle>1Xhub</SliderCardTitle>
              <SliderCardDescription>Tester</SliderCardDescription>
            </SliderCard>
          </SliderItem>
        </SliderList>
      </SliderContainer> */}
    </GuildMembersSectionContainer>
  );
}

export default GuildMemberSection;
