import {
  WelcomeSectionContainer,
  ExpandIcon,
  GuildName,
  GettingStartedButton,
  WelcomeMessage,
  WelcomeMessageContainer,
} from "./welcome-section-elements";

function WelcomeSection() {
  return (
    <WelcomeSectionContainer id="home">
      <WelcomeMessageContainer>
        <WelcomeMessage>O</WelcomeMessage>
        <WelcomeMessage>m</WelcomeMessage>
        <WelcomeMessage>n</WelcomeMessage>
        <WelcomeMessage>i</WelcomeMessage>
        <WelcomeMessage>b</WelcomeMessage>
        <WelcomeMessage>i</WelcomeMessage>
        <WelcomeMessage>t</WelcomeMessage>
      </WelcomeMessageContainer>
      <GuildName>Software</GuildName>
      <GettingStartedButton to="about"> Our Services</GettingStartedButton>
      <ExpandIcon />
    </WelcomeSectionContainer>
  );
}

export default WelcomeSection;
